html, body {
  max-width: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  font-family: 'Bebas Neue', sans-serif;
  color: whitesmoke;
  text-transform: uppercase;
}
p {
  color: whitesmoke;
  font-family: 'Kanit', sans-serif;;
}
.navMenu {
  background-color: rgb(33,37,42)
}
.navbar-items {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 1.4rem !important;
  margin-left:18px;
}
.navbar-toggler-icon {
  background-image: url(./img/icon-menu.png)!important;
}

.hero-icons {
  font-size: 2.8rem;
  background: #7F00FF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #00c6fb 0%, #005bea 100%);   /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #00c6fb 0%, #005bea 100%);  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-icons:hover {
  background: #7F00FF;  /* fallback for old browsers */
  background: -webkit-radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% );   /* Chrome 10-25, Safari 5.1-6 */
  background: radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% );  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-container {
  background-image: linear-gradient(to top, #434343 0%, black 100%);

}
.profile-picture {
  background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
  border-radius: 33% 67% 66% 34% / 32% 47% 53% 68%  ;

}

.hero-container-info{
  overflow-x: hidden;
}

.skills-container{
  gap: 10px;
}
.skills-tags{
  background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
  padding: 8px 8px;
  gap: 20px;
  border-radius: 6px;
}
.portfolio-card {
  box-shadow: 10px 10px 11px -8px rgba(0,0,0,0.43);
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0,0,0,0.43);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0,0,0,0.43);
}
.card-title {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 25px !important;
}
.btn-cards{
  background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
}
.footer-icons {
  font-size: 1.8rem;
  background: #7F00FF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #00c6fb 0%, #005bea 100%);   /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #00c6fb 0%, #005bea 100%);  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer-icons:hover {
  background: #7F00FF;  /* fallback for old browsers */
  background: -webkit-radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% );   /* Chrome 10-25, Safari 5.1-6 */
  background: radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% );  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 719px) {  
  .icon-container {    
    visibility: collapse;
  } 
  .tabs-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}